@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Open Sans';
    /* background: #ede5f79b; */
}

.rbc-event{
    padding: 0px !important
}

html::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
html::-webkit-scrollbar-thumb {
    background: #555;
    /* color: #5c1ab21a */
}

html::-webkit-scrollbar {
    width: 4px;
}


/* .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border-color: #5D1AB2;
  }

  .ant-checkbox.foo span.ant-checkbox-inner {
    background-color: hotpink;
    border-radius: 50%;
  }


  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    color: #5D1AB2;
    border-color: blue;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-width: 3px;
    border-radius: 5px;
    border-color: red;
  } */



  .ant-select-selection {
    background-color: green;
  }

  .ant-select-selection {
    max-height: 51px;
    overflow: auto;
  }

  .ant-select-selector {
    background-color: green;
  }

  :where(.css-dev-only-do-not-override-1e3x2xa).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #F8F8F8;
    border: none;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 50px;
    border-radius: 5px !important;
}

:where(.css-1e3x2xa).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #F8F8F8;
  border: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 50px;
  border-radius: 5px !important;
}